import React, {Component} from "react";
import Container from 'react-bootstrap/Container';
import Registration from "../auth/registration";


class Register extends Component {
    render() {
        
        return (
           <Container className="mrg">
           <Registration/>
           </Container> 
        )
    }
}
export default Register;