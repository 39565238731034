import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import img1 from '../../../product_images/img1.jpg';
import img2 from '../../../product_images/img2.jpg';
import img3 from '../../../product_images/img3.jpg';
import img4 from '../../../product_images/img4.jpg';
import img5 from '../../../product_images/img5.jpg';
import img6 from '../../../product_images/img6.jpg';
import img7 from '../../../product_images/img7.jpg';
import img8 from '../../../product_images/img8.jpg';
import img9 from '../../../product_images/img9.jpg';
import {Link} from "react-router-dom";


function CarouselMain() {
  return (
    <>
      <Carousel className="carouselHome">
        <Carousel.Item>
            <Link to="/Shop">
          <img
            className="carouselItem1"
            src={img1}
            alt="First slide"
          />
          <img
            className="carouselItem1"
            src={img2}
            alt="Second Slide"
          />
          <img
            className="carouselItem1"
            src={img3}
            alt="First slide"
          />
          </Link>
        </Carousel.Item>
        <Carousel.Item>
        <Link to="/Shop">
        <img
            className="carouselItem2"
            src={img4}
            alt="First slide"
          />
          <img
            className="carouselItem2"
            src={img5}
            alt="Second Slide"
          />
          <img
            className="carouselItem2"
            src={img6}
            alt="First slide"
          />
          </Link>
        </Carousel.Item>
        <Carousel.Item>
        <Link to="/Shop">
        <img
            className="carouselItem3"
            src={img7}
            alt="First slide"
          />
          <img
            className="carouselItem3"
            src={img8}
            alt="Second Slide"
          />
          <img
            className="carouselItem3"
            src={img9}
            alt="First slide"
          />
          </Link>
        </Carousel.Item>
      </Carousel>
      </>
  );
}

export default CarouselMain;