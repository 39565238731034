import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ToastContainer, toast } from 'react-toastify';
import Carousel from 'react-bootstrap/Carousel';
import { Redirect } from 'react-router-dom';
//import CountdownTimer from '../misc/countdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as FStar} from '@fortawesome/free-solid-svg-icons';
import { faStar as Star, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
//import axios from 'axios';
import Countdown from 'react-countdown';
import placeholder from '../../imgs/placeholder.jpg';

var store = require ('store')

class detailProduct extends Component {
constructor(props){
    super(props);
        this.state ={
            bid : '',
            error: null,
            favorites : []
        }
}
chamarfuncao(product){
    var pos1 = product.meta_data;
    var difference = "";
  
     for (let x = 0; x<pos1.length; x++){
        if(pos1[x].key === "woo_ua_auction_end_date"){
            difference = new Date(Date.parse(pos1[x].value));
            
        }
      }
    return difference;
  };

  getQuantity(product){
      var pos = product.meta_data;
      var quantity ="";

      for (let i = 0; i < pos.length; i++){
          if(pos[i].key==="woo_ua_max_quantity"){
              quantity = pos[i].value;
          }
      }
      return quantity;
  };

  getRetail(product){
    var pos = product.meta_data;
    var retail ="";

    for (let i = 0; i < pos.length; i++){
        if(pos[i].key==="woo_ua_retail_value"){
            retail = pos[i].value;
        }
    }
    return retail;
};

getMinBid(product){
    var pos = product.meta_data;
    var minbid ="";

    for (let i = 0; i < pos.length; i++){
        if(pos[i].key==="woo_ua_opening_price"){
            minbid = pos[i].value;
        }
    }
    return minbid;
};

getTransport(product){
    var pos = product.meta_data;
    var transport ="";

    for (let i = 0; i < pos.length; i++){
        if(pos[i].key==="woo_ua_transport"){
            transport = pos[i].value;
        }
    }
    return transport;
};


  addFavorite(){
    var userdata = JSON.parse(localStorage.getItem('user'));
    var user_id = userdata.id;
    var productdata = JSON.parse(localStorage.getItem('displayedProduct'));
    var post_id = productdata.id;
    let formData = new FormData();
        formData.append('user_id', user_id);
        formData.append('post_id', post_id);
          

    fetch('https://backoffice.stocklife.eu/wp-json/srra/v1/uwa_watch',{
      method: "POST",
      body : formData})
      .then(response => console.log(response))
      .catch(error =>{
        console.log(error);
      });
      window.location.reload(false);
  }

  componentDidMount(){
    const user = JSON.parse(localStorage.getItem('user'));
    const IDuser = JSON.stringify(user.id);
    fetch(`https://backoffice.stocklife.eu/wp-json/srra/v1/favorites?user_id=${IDuser}`)
    .then(response => response.json())
    .then(result => this.setState({favorites: result[0], isLoading: false}));
  }

    render() {
        if(!localStorage.getItem('user')){
			return <Redirect to="/login"/>;
		}
        var data = (this.props.location.product && this.props.location.product.data) ? this.props.location.product.data : false;
        const storeData = store.get('displayedProduct');
        if (data){
            store.set('displayedProduct', data);
        } else if(storeData){
            data = storeData;
        } else {
            toast.error('Error');
        }

        const Completion = () => <span>Closed</span>;
        const renderer = ({days, hours, minutes, seconds, completed})=>{
        if(completed){
          return <Completion/>
        } else{
          return(
            <span>
              {days}d {hours}h {minutes}m {seconds}s
            </span>
          );
        }
      };
      const {favorites} = this.state;
      let fav = [];
      if(favorites!== undefined){
          let favoritesvalues = favorites.map(fav => (fav));
          favoritesvalues = favoritesvalues.filter((item, i, ar) => ar.indexOf(item) === i);
          for (let a=0; a<favoritesvalues.length; a++){
                  if (parseInt(favoritesvalues[a]) === parseInt(data.id)){
                      fav.push(favorites[a])
                  }
          }
      }
     fav = fav.filter((item, i, ar) => ar.indexOf(item) === i);
        return (
            <Container className="mrg">
                <ToastContainer/>
            <h2 className="prodName">{data.name}</h2>
            <Row>
                <Col lg={7} xs={12}>
                <div className="prodGallery">
                <Carousel>
                    {data.images.map((image)=>
                        <Carousel.Item key={image.id}>
                        <img className="d-block w-100 prodGalleryItem" src={image ? image.src : placeholder} alt="gallery"/>
                        </Carousel.Item>
                    )}
                </Carousel>
                </div>
                </Col>
                <Col lg={5} xs={12}>
                <div className="helpButton"><a href="mailto:info@stocklife.eu?subject=Help%20with%20product&body=Please%20Indicate%20The%20Name%20Of%20The%20Product"><FontAwesomeIcon icon={faQuestionCircle} title="Do you have a question about this product?"/> Help</a></div>
                <span><h5 className="prodCat">Category:</h5> <div dangerouslySetInnerHTML={{  __html:data.categories[0].name}}/></span><br/>
                <span><h5 className="prodTime">Time left: </h5> <Countdown date = {this.chamarfuncao(data)} renderer={renderer}/></span><br/>
                <div className="prodHigh">
                <span onClick={this.addFavorite} className="prodFav">{parseInt(fav) === parseInt(data.id) ? <FontAwesomeIcon icon={FStar} /> : <FontAwesomeIcon icon={Star} /> } Favoriten</span><br/><br/>
                <span><h5 className="prodMax">Quantity: </h5> <span className="prodValue">{this.getQuantity(data)} stuks</span></span><br/>
                <span><h5 className="prodRetail">Retail: </h5> <span className="prodValue">{this.getRetail(data)}€ </span></span><br/><br/>
                <span><h5 className="prodRetail">Min. bid: </h5> <span className="prodValue">{this.getMinBid(data)}€ </span></span><br/><br/>
                <label className="prodBid">Bid:</label><br/>
                    <form method="POST" onSubmit={this.handleSubmit.bind(this)}>
                    <input type="number" className="bidValue" name="bid" id="bid" value={this.state.bid} onChange={this.handleChange}/><br/>
                    <button type="submit" className="bidButton">Place Bid</button>
                    </form>
                </div>
                <p className="handlingTransport">Handling & transportkosten: <span>{this.getTransport(data)} €</span>, - excl. BTW (Verzending in Nederland).</p>
                <p className="BTW">Op het geboden bedrag wordt een provisie van 10% berekend. Biedingen zijn excl. BTW.</p>
                </Col>
            </Row>
            <hr/>
            <div className="mt-5">
                <h5 className="prodDesc">Beschrijving</h5>
        <div dangerouslySetInnerHTML={{  __html:data.description}}/>
            </div>
            </Container>
        )
    }


    handleChange = (e) => {
        this.setState({bid: e.target.value});
      }

      resetForm(){
        this.setState({bid:''})
     }
    
    
      handleSubmit(e){
          //testing
          //end testing
          if (window.confirm(`Do you want to bid ${this.state.bid}€?`)){
          e.preventDefault();
          this.resetForm();
          let user = JSON.parse(localStorage.getItem('user'));
          let user_id = user.id;
          let token = user.authToken;
          let prod = JSON.parse(localStorage.getItem('displayedProduct'));
          let prod_id = prod.id;
          let formData = new FormData();
          formData.append('type', 'bid');
          formData.append('userid', user_id);
          formData.append('product_id', prod_id);
          formData.append('bid',this.state.bid);
          formData.append('token', token);
          console.log(user_id, prod_id, this.state.bid )
          

          return fetch('https://backoffice.stocklife.eu/wp-json/srra/v1/bidit',{
              method: 'POST',
              body: formData
          })
          .then(function(response){
              response.json().then(function(data){
                  console.log(data);
                  toast.success("Your bid was submited. Thank you!");
              })
          })
          .catch(function(error){
              console.log("Fecth Error: ", error);
          })
      } else{
          e.preventDefault();
      }
    
    }
}
export default detailProduct;