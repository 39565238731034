import React from 'react';
import Container from 'react-bootstrap/Container';
import ReactPlayer from "react-player";
import foto from '../../imgs/fotonews.png';


function Pitch() {
  return (
    <>
      <Container className="pitch">
        <h2>Animatie Elevator Pitch Stocklife - 
          De online veiling website om restvoorraden een nieuw leven te geven!</h2>
          <div className="video"><ReactPlayer url="https://youtu.be/EDiap_dtHLw"/></div>
          <p>Beste Bezoekster/ Bezoeker</p>
          <p>Wat leuk dat u onze <b>Stocklife</b> website bezoekt en de animatie van onze Elevator Pitch bekijkt. 
            Daar worden wij vrolijk van!</p>
          <p>We laten graag kort en krachtig (in maar <b>60 seconden</b>) 
            zien waar Stocklife voor staat en wat we u als ondernemer kunnen bieden.</p>
          <p>Stocklife is exclusief voor bedrijven en is geheel <b>anoniem</b>. 
            Dat betekent de beste producten tegen eerlijke prijzen.</p>
          <p>Bent u nieuwsgierig geworden naar het <b>concept</b> van Stocklife? Heel Slim! 
            Want op onze website zorgen wij ervoor dat kopende en verkopende bedrijven elkaar ontmoeten, 
            zodat er weer nieuw leven wordt geblazen in de “<b>restvoorraden</b>”.</p>
          <p>Geen verspilling (<b>NO</b> waste) of zoals wij het ook wel noemen; The Blue way.</p>
          <p>Door de snelle groei van onze community weten we dat er veel vraag is naar datgene wat wij u kunnen bieden. 
            We zouden u dan ook graag verwelkomen om <b>lid</b> te worden (<b>blue member</b>) van Stocklife.</p>
          <p>Momenteel is registreren geheel kostenloos! Dus langer wachten is niet nodig!  
            Registreer u vandaag nog en klik <a href="https://backoffice.stocklife.eu/registreren/">hier!</a></p>
          <p>Wilt u op de hoogte gehouden worden van de laatste stockupdates zodat u direct op de hoogte bent van het laatste nieuwe aanbod? 
            Laat dan <u>hier</u> uw email adres achter en blijf op de hoogte van alle <b>ontwikkelingen</b> binnen Stocklife.</p>
          <p>Heeft u vragen? Mail of bel ons gerust. We staan u graag te woord.</p><br/>
          <p>Met vriendelijke voorraadgroet,</p>
          <p>Debbie Eijkemans
            <br/>debbie@stocklife.eu
            <br/>06-13 32 00 25</p>
          <p>Leon vd Elsen
            <br/>leon@stocklife.eu
            <br/>06-15 30 97 26</p>
            <div className="pitchImg">
              <img src={foto} alt="foto"/>
              <h5>Debbie & Leon</h5>
              <h6>The Blue Team</h6>
            </div>
      </Container>
      <Container fluid className="pitchRegister">
      <Container>
        <h2>Register now! It's Free!</h2>
        <a href="https://backoffice.stocklife.eu/registreren/"><button className="stockButton2 mt-4">Register</button></a>
      </Container>
      </Container>
      <Container className="pitch2">

      </Container>
    </>
  );
}

export default Pitch;