import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import icon1 from '../../imgs/Icon1.png';
import icon2 from '../../imgs/Icon2.png';
import icon3 from '../../imgs/Icon3.png';
import icon4 from '../../imgs/Icon4.png';
import icon5 from '../../imgs/Icon5.png';  
import icon6 from '../../imgs/Icon6.png';
import {Link} from "react-router-dom";
import MailchimpSubscribe from "react-mailchimp-subscribe";


function Kopen() {
  return (
    <>
      <Container>
        <h2 className="kopenHeading">HOE WERKT HET?</h2>
        <p>
          Stocklife is een online veilingplatform voor ondernemers waarbij je anoniem kunt bieden op restvoorraden van andere bedrijven. 
          Indien je de hoogste bieder bent en boven het minimaal gewenste bod hebt geboden, dan heb je deze kavel (lot) gewonnen. 
          Onderstaand is het proces visueel uitgelegd.
        </p>
      </Container>
      <Container>
        <Row>
          <Col lg={4} sm={7} xs={12}><h1 className="kopenTitle">Bieden & kopen bij Stocklife </h1></Col>
        </Row> 
      </Container>
      <Container fluid className="kopenLight">
        <Container fluid className="kopenDark">
        <Container className="kopenHow">
          <Row className="kopenHowRow">
            <Col lg={2} sm={12} className="kopenHowItemDark">
              <div>
                <img src={icon1} alt="register"/>
                <div className="kopenHowItemNumber">1</div>
                <div className="kopenHowItemName">registreer</div>
              </div>
            </Col>
            <Col lg={2} sm={12} className="kopenHowItemLight">
            <div>
                <img src={icon2} alt="register"/>
                <div className="kopenHowItemNumber">2</div>
                <div className="kopenHowItemName">WIJ NEMEN CONTACT OP + INTAKE</div>
              </div>
            </Col>
            <Col lg={2} sm={12} className="kopenHowItemDark">
            <div>
                <img src={icon3} alt="register"/>
                <div className="kopenHowItemNumber">3</div>
                <div className="kopenHowItemName">START MET BIEDEN<br/><span className="small-letters">(GEHEEL ANONIEM)</span></div>
              </div>
            </Col>
            <Col lg={2} sm={12} className="kopenHowItemLight">
            <div>
                <img src={icon4} alt="register"/>
                <div className="kopenHowItemNumber">4</div>
                <div className="kopenHowItemName">JE ONTVANGT EEN BERICHT VAN JOUW BIEDING EN ALS JE HEBT GEWONNEN NEMEN WE NA HET AFLOPEN VAN DE VEILING CONTACT MET JE OP</div>
              </div>
            </Col>
            <Col lg={2} sm={12} className="kopenHowItemDark">
            <div>
                <img src={icon5} alt="register"/>
                <div className="kopenHowItemNumber">5</div>
                <div className="kopenHowItemName">WIJ VERZORGEN VOOR U HET TRANSPORT</div>
              </div>
            </Col>
            <Col lg={2} sm={12} className="kopenHowItemLight">
            <div>
                <img src={icon6} alt="register"/>
                <div className="kopenHowItemNumber">6</div>
                <div className="kopenHowItemName">BETAAL VEILIG EN EENVOUDIG</div>
              </div>
            </Col>
          </Row>
        </Container>
        </Container>
      </Container>
      <Container className="kopenBieden">
        <Row>
          <Col lg={7} className="kopenFix">
            <h3>Bieden op de beste handel</h3>
            <p>Voordelig en anoniem producten inkopen? Dat is simpel met Stocklife!</p>
            <p>Om te kunnen bieden op andermans restvoorraden dient u eerst zich te registreren. 
              Heeft u dit gedaan? Dan nemen wij contact met u op. 
              Als volgt kunt u starten met bieden, dit gebeurt volledig anoniem. 
              De enige die hier inzicht in heeft is Stocklife. 
              Wanneer uw bod wordt geaccepteerd ontvangt u van ons een bericht. 
              Het transport wordt vervolgens geregeld en u hoeft alleen maar de betaling te verzorgen. 
              So easy!</p>
            <p>Kortom, wij zitten er volledig tussen. 
              Het enige wat u hoeft te doen is u eenmalig te registreren en te bieden op de producten die uw aanspreken. 
              De rest wordt geregeld!</p>
              <div>
                <Link to="/shop"><button className="stockButton2 mr-4">NAAR HET AANBOD »</button></Link>
                <a href="https://backoffice.stocklife.eu/registreren/"><button className="stockButton2">OF REGISTREER NU »</button></a>
              </div>
          </Col>
          <Col lg={5}>
            <div className="kopenNewsletter">
              <h3>Schrijf je in voor de updates!</h3>
              <MailchimpSubscribe url="https://stocklife.us20.list-manage.com/subscribe/post?u=12359c973990e0082a90e8cf1&amp;id=2bd42a8409" />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Kopen;