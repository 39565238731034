import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebookSquare, faInstagram, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import footerimg from '../../imgs/footer-img.gif';


function Footer() {
  return (
    <footer>
      <Container fluid className="footer">
      <Container>
        <Row>
          <Col xs={12} lg={3}>
          <div>
            <h5>SNEL NAAR</h5>
            <ul className="footer-menu">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/overons">Over ons</Link></li>
              <li><Link to="/aanbieden">Aanbieden</Link></li>
              <li><Link to="/verkopen">Hoe het werkt: Verkopen</Link></li>
              <li><Link to="/kopen">Hoe het werkt: Kopen</Link></li>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/myaccount">Mijn account</Link></li>
            </ul>
          </div>
          </Col>
          <Col xs={12} lg={3}>
            <div>
              <h5>CONTACT</h5>
              <p className="contact-footer">Duinweg 15-17
                <br/>5482VR Schijndel 
                <br/>Nederland</p>
                <p>info@stocklife.eu</p>
                <p>Contact details:</p>
                <p>Debbie van den Langenberg - Eijkemans
                <br/>Blue-manager®
                <br/>+31-613 320 025</p>
                <p>Leon vd Elsen
                <br/>Blue-manager®
                <br/>+31-615 309 726</p>
            </div>
          </Col>
          <Col xs={12} lg={4}>
          <div>
              <h5>WIL JE NIETS MISSEN? SCHRIJF JE IN VOOR DE UPDATES</h5>
              <MailchimpSubscribe url="https://stocklife.us20.list-manage.com/subscribe/post?u=12359c973990e0082a90e8cf1&amp;id=2bd42a8409" />
              <img src={footerimg} alt="Professionals" width="200" height="200"/>
            </div>
          </Col>
          <Col xs={12} lg={2}>
          <h5>SOCIAL MEDIA</h5>
          <div>
                    <span className="contactSocial">
                        <a href="https://www.linkedin.com/company/stocklifebv/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedin}/>
                        </a>
                    </span>
                    <span className="contactSocial">
                        <a href="https://www.facebook.com/StocklifeAuction/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebookSquare}/>
                        </a>
                    </span>
                    <span className="contactSocial">
                        <a href="https://www.instagram.com/stocklife.eu/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram}/>
                        </a>
                    </span>
                    <span className="contactSocial">
                        <a href="https://www.youtube.com/channel/UCLL3LyMV-giOhTcZH5FVuIA" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faYoutubeSquare}/>
                        </a>
                    </span>
                </div>
          </Col>
        </Row>
      </Container>
      </Container>
      <Container fluid className="footerbar">
      <Container>
        <Row className="footerbar-content">
          <Col lg={6} sm={12}>
            <p>© Copyright Stocklife</p>
          </Col>
          <Col lg={6} sm={12} className="privacy"><p><Link to="/privacy">Privacy and cookie statement</Link> | <Link to="/terms"> Terms of use</Link></p></Col>
        </Row>
      </Container>
      </Container>
    </footer>
  );
}

export default Footer;