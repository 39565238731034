import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import icon1 from '../../imgs/Icon1.png';
import icon2 from '../../imgs/Icon2.png';
import icon7 from '../../imgs/Icon7-1.png';
import icon8 from '../../imgs/Icon8.png';
import icon5 from '../../imgs/Icon5.png';  
import icon9 from '../../imgs/Icon9.png';
import {Link} from "react-router-dom";


function Verkopen() {
  return (
    <>
      <Container>
        <h2 className="kopenHeading">HOE WERKT HET?</h2>
        <p>
          Stocklife is een online veilingplatform voor ondernemers waarbij je anoniem kunt bieden op restvoorraden van andere bedrijven.
          Deze restvoorraden kun je bij ons anoniem aanbieden en wij zorgen ervoor dat deze op onze site komen te staan. 
          Wij nemen al het werk voor jouw uit handen en hanteren ook: Niet verkocht is niets betalen (no cure no pay). 
          Onderstaand wordt het proces van producten aanbieden visueel uitgelegd.
        </p>
      </Container>
      <Container>
        <Row>
          <Col lg={6} sm={7} xs={12}><h1 className="kopenTitle">Aanbieden & verkopen met Stocklife</h1></Col>
        </Row> 
      </Container>
      <Container fluid className="kopenLight">
        <Container fluid className="kopenDark">
        <Container className="kopenHow">
          <Row className="kopenHowRow">
            <Col lg={2} sm={12} className="kopenHowItemDark">
              <div>
                <img src={icon1} alt="register"/>
                <div className="kopenHowItemNumber">1</div>
                <div className="kopenHowItemName">registreer</div>
              </div>
            </Col>
            <Col lg={2} sm={12} className="kopenHowItemLight">
            <div>
                <img src={icon2} alt="register"/>
                <div className="kopenHowItemNumber">2</div>
                <div className="kopenHowItemName">WIJ NEMEN CONTACT OP + INTAKE</div>
              </div>
            </Col>
            <Col lg={2} sm={12} className="kopenHowItemDark">
            <div>
                <img src={icon7} alt="register"/>
                <div className="kopenHowItemNumber">3</div>
                <div className="kopenHowItemName">ONTVANG HET BESTE BOD</div>
              </div>
            </Col>
            <Col lg={2} sm={12} className="kopenHowItemLight">
            <div>
                <img src={icon8} alt="register"/>
                <div className="kopenHowItemNumber">4</div>
                <div className="kopenHowItemName">Accepteer<br/><span className="small-letters">(of niet)</span></div>
              </div>
            </Col>
            <Col lg={2} sm={12} className="kopenHowItemDark">
            <div>
                <img src={icon5} alt="register"/>
                <div className="kopenHowItemNumber">5</div>
                <div className="kopenHowItemName">WIJ VERZORGEN VOOR U HET TRANSPORT</div>
              </div>
            </Col>
            <Col lg={2} sm={12} className="kopenHowItemLight">
            <div>
                <img src={icon9} alt="register"/>
                <div className="kopenHowItemNumber">6</div>
                <div className="kopenHowItemName">WIJ REGELEN DE BETALING</div>
              </div>
            </Col>
          </Row>
        </Container>
        </Container>
      </Container>
      <Container className="kopenBieden">
        <Row>
          <Col lg={12} >
            <h3>Anoniem het beste krijgen!</h3>
            <p>Wilt u van uw <b>restvoorraden</b> af? Dat is met Stocklife heel simpel!</p>
            <p>Vul het formulier in en ontvang van ons alleen het beste bod. 
                U ontvangt dus geen e-mails met niet benoemingswaardige biedingen. 
                Op deze manier kunnen wij ook voor alle <b>partijen</b> volledige anonimiteit garanderen. 
                U bepaald zelf of u het bod wilt accepteren of niet. 
                Vervolgens regelen wij voor u het transport en zorgen ervoor dat het op de juiste locatie terecht komt. 
                Uiteraard <b>regelen</b> wij ook de betaling.</p>
            <p>Kortom, wij zitten er volledig tussen. 
                Het enige wat u hoeft te doen is het formulier in te vullen en het <b>bod te accepteren</b>, 
                de rest wordt geregeld!</p>
              <div>
                <Link to="/aanbieden"><button className="stockButton2">BIED NU UW PRODUCT AAN »</button></Link>
                
              </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Verkopen;