import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCog, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';




function Header() {
  return (
    <header>
        <Container fluid className="topbar">
        <a href="tel:0031613320025"><Container>
            <div><FontAwesomeIcon icon={faPhoneAlt}/> +31-613 320 025</div>
            </Container></a>
        </Container>
        <Container>
            <Navbar collapseOnSelect expand="lg" variant="dark">
                <Navbar.Brand><Link to="/"><div className="logo"></div></Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
            <NavDropdown title="Over ons" id="collasible-nav-dropdown" className="navItem">
                <NavDropdown.Item href="/pitch">Waarom Stocklife?</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/nieuws">Nieuws</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/overons">Over ons</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Hoe werkt het" id="collasible-nav-dropdown" className="navItem">
                <NavDropdown.Item href="/kopen">Kopen</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/verkopen">Verkopen</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Alle Veilingen" id="collasible-nav-dropdown" className="navItem">
            <NavDropdown.Item href="/shop">Veilingen</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/binnenkort">Binnenkort</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/gesloten" >Gesloten veilingen</NavDropdown.Item>
            </NavDropdown>
                <Nav.Item className="navItem nodrop"><Link to="/aanbieden">Aanbieden</Link></Nav.Item>
                <Nav.Item className="navItem nodrop"><Link to="/contact">Contact</Link></Nav.Item>
                <Nav.Item className="navItem nodrop"><Link to="/login"><FontAwesomeIcon icon={faUserCog}/></Link></Nav.Item>
            </Nav>
            </Navbar.Collapse>
            </Navbar>
                
        </Container>
    </header>
  );
}

export default Header;