import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormContainer from '../../components/misc/NewFormContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsappSquare } from '@fortawesome/free-brands-svg-icons';
import { faPlay } from '@fortawesome/free-solid-svg-icons';




function Aanbieden() {
  return (
    <>
    <Container className="mrg">
      <Row>
        <Col lg={8} sm={12}>
        <div className="aanbiedenText">
          <h1>Aanbieden (Verkopen)</h1>
            <p>Wilt u uw (overtollige) voorraad verkopen en dit omzetten in rendement? Hierdoor krijgt u meer opslagruimte.</p>
            <p>Laat uw gegevens achter en wij nemen contact met u op. So Easy <span role="img" aria-label="emoji">😉</span></p>
            <p>Tijdelijk is ook het promotie-pakket gratis en wij hanteren no cure no pay.</p>
          </div>
         <FormContainer/>
        <div className="aanbiedenWS">
            <h3>Stuur ons uw producten via WhatsApp</h3>
            <a href="https://wa.me/31613320025">
              <FontAwesomeIcon icon={faWhatsappSquare} />
              </a>
          </div>
        </Col>
        <Col lg={4} sm={12}>
          <div className="aanbiedenWaarom">
            <h4>Waarom stocklife?</h4>
            <ul className="aanbiedenList">
              <li><FontAwesomeIcon icon={faPlay} /> Goed gereld</li>
              <li><FontAwesomeIcon icon={faPlay} /> Het beste bod</li>
              <li><FontAwesomeIcon icon={faPlay} /> Veilig en anoniem</li>
              <li><FontAwesomeIcon icon={faPlay} /> Vrijblijvend</li>
              <li><FontAwesomeIcon icon={faPlay} /> Zo gedaan!</li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
    </>
  );
}

export default Aanbieden;