import React, { Component } from 'react';


export default class Registration extends Component {
    constructor(){
        super();
        this.state={
            name: '',
            email: '',
            telephone: '',
            company: '',
            password: '',
            interest: '',
            consent: null,
            selectedOption: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e){
        let target = e.target;
        let value = target.value;
        let name = target.name;
        this.setState({
            [name] : value,
            selectedOption : e.target.value
        })
    }


    handleSubmit(e){
        e.preventDefault();
    }



    render(){
        return(
        <>
        <div className="registerContainer">
        <form onSubmit={this.handleSubmit}>
        <div className="form-group">
                <label>Gebruikersnaam *</label>
                <input type="text" className="form-control" id="name" name ="name" value={this.state.name} onChange={this.handleChange} required/>
            </div>
            <div className="form-group">
                <label>E-mailadres *</label>
                <input type="email" className="form-control" id="email" name ="email" value={this.state.email} onChange={this.handleChange} required/>
            </div>
            <div className="form-group">
                <label>Telefoon *</label>
                <input type="text" className="form-control" id="telephone" name ="telephone" value={this.state.telephone} onChange={this.handleChange} required/>
            </div>
            <div className="form-group">
                <label>Bedrijfsnaam  *</label>
                <input type="text" className="form-control" id="company" name ="company" value={this.state.company} onChange={this.handleChange} required/>
            </div>
            <div className="form-group">
                <label>Wachtwoord *</label>
                <input type="password" className="form-control" id="password" name ="password" value={this.state.password} onChange={this.handleChange} required/>
            </div>
            <div className="form-group">
                <label>Geïnteresseerd in: *</label>
                <div className="form-check">
                <label className="form-check-label">
                <input className="form-check-input" type="radio" name="interest" id="option1" value="kopen" checked={this.state.selectedOption === 'kopen'} onChange={this.handleChange}/>
                
                    Kopen
                </label>
            </div>
            <div className="form-check">
                <label className="form-check-label">
                <input className="form-check-input" type="radio" name="interest" id="option2" value="verkopen" checked={this.state.selectedOption === 'verkopen'} onChange={this.handleChange}/>
                    Verkopen
                </label>
            </div>
            <div className="form-check">
                <label className="form-check-label">
                <input className="form-check-input" type="radio" name="interest" id="option3" value="beide" checked={this.state.selectedOption === 'beide'} onChange={this.handleChange}/>
                    Beide
                </label>
            </div>
            </div>
            <div className="form-group form-check">
                <input type="checkbox" className="form-check-input" id="consent" name ="consent" value="consent" checked={this.state.consent === 'consent'} onChange={this.handleChange} required/>
                <label className="form-check-label">Gebruiksvoorwaarden *</label>
                <small className="form-text">Ik accepteer de gebruikersvoorwaarden.</small>
            </div>
            <button type="submit" className="btn btn-primary">Registreren</button>
        </form>
        </div>
        </>
        );
    }
}