import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';
import './assets/css/custom.css';
import './assets/css/footer.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './assets/components/headerComponents/header';
import Footer from './assets/components/footerComponents/footer';
import Homepage from './assets/components/pages/homePage';
import Pitch from './assets/components/pages/pitch';
import Kopen from './assets/components/pages/kopen';
import Verkopen from './assets/components/pages/verkopen';
import Promotiepakket from './assets/components/pages/promotiepakket';
import OverOns from './assets/components/pages/over';
import Nieuws from './assets/components/pages/nieuws';
import ScrollToTop from'./assets/components/misc/scrolltotop';
import Contact from './assets/components/pages/contact';
import Aanbieden from './assets/components/pages/aanbieden';
import Login from './assets/components/pages/login';
import Signup from './assets/components/pages/register';
import Shop from './assets/components/pages/shop';
import detailProduct from './assets/components/pages/detailProduct';
import MyAccount from './assets/components/pages/myAccount';
import Terms from './assets/components/pages/terms';
import Privacy from './assets/components/pages/privacy';
import Gesloten from './assets/components/pages/gesloten';
import oldDetailProduct from './assets/components/pages/oldDetailProduct';
import Binnenkort from './assets/components/pages/binnenkort';

class App extends Component {
  state = {
      data: null
    };
  
    render() {
      return (
        <div>
      <Router>
        <ScrollToTop/>
        <Header/>
          <Route exact path="/" component={Homepage}/>
          <Route exact path="/pitch" component={Pitch}/>
          <Route exact path="/kopen" component={Kopen}/>
          <Route exact path="/verkopen" component={Verkopen}/>
          <Route exact path="/promotiepakket" component={Promotiepakket}/>
          <Route exact path="/overons" component={OverOns}/>
          <Route exact path="/nieuws" component={Nieuws}/>
          <Route exact path="/contact" component={Contact}/>
          <Route exact path="/aanbieden" component={Aanbieden}/>
          <Route path="/login" component={Login} />
          <Route exact path="/register" component={Signup}/>
          <Route exact path="/shop" component={Shop}/>
          <Route exact path="/binnenkort" component={Binnenkort}/>
          <Route exact path="/gesloten" component={Gesloten}/>
          <Route path="/detailproduct/:slug" component={detailProduct}/>
          <Route path="/myaccount" component={MyAccount}/>
          <Route path="/terms" component ={Terms}/>
          <Route path="/privacy" component={Privacy}/>
          <Route path="/olddetailproduct" component={oldDetailProduct}/>
        <Footer/>
      </Router>
    </div>
      );
    }
  }
  export default App;