import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Redirect, Link } from 'react-router-dom';
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api"; // Supports ESM

const getProducts = new WooCommerceRestApi({
    url: 'https://backoffice.stocklife.eu/',
    consumerKey: 'ck_b1c80573279ce73665e33d52a9c2c7819309c871',
    consumerSecret: 'cs_43dfbd043b03acfb6ffbc5e2d51abe0e84cdd6f6',
    version: 'wc/v3',
    queryStringAuth: true,
    config: {
      headers: {
        'Access-Control-Allow-Origin': '*',
      }
  }
  });

export default class MyAccount  extends Component {

    constructor(props) {
        super(props);
     
        this.state = {
          bids: [],
          isLoading : false,
          favorites : [],
          products:[]
        };
      }
      FormatBids(num) {
        return (
          num
            .toFixed(0) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
        ) // use . as a separator
      }
    
      componentDidMount() {
        // Simple GET request using fetch
        const user = JSON.parse(localStorage.getItem('user'));
        const IDuser = JSON.stringify(user.id);
        this.setState({isLoading:true});
        
        getProducts.get("products?per_page=42")
        .then(result => this.setState({
          products: result.data
        }))
        .catch(error => this.setState({
          error
        }));
        
        fetch('https://backoffice.stocklife.eu/wp-json/srra/v1/qauction')
            .then(response => response.json())
            .then(data => this.setState({ bids: data, isLoading: false}));
        fetch(`https://backoffice.stocklife.eu/wp-json/srra/v1/favorites?user_id=${IDuser}`)
        .then(response => response.json())
        .then(result => this.setState({favorites: result[0], isLoading: false}));
    }
    
	render() {

        if(!localStorage.getItem('user')){
			return <Redirect to="/login"/>;
        }
        const {favorites,isLoading, products} = this.state;
        

        if (isLoading) {
            return <div className="fullScreen"><div className="sk-folding-cube">
            <div className="sk-cube1 sk-cube"></div>
            <div className="sk-cube2 sk-cube"></div>
            <div className="sk-cube4 sk-cube"></div>
            <div className="sk-cube3 sk-cube"></div>
          </div></div>;
          } 

        const user = JSON.parse(localStorage.getItem('user'));
        const username = user.nicename;
        const IDuser = JSON.stringify(user.id);
        const {bids} = this.state;
        let info=[];

        for (let i = 0; i<bids.length; i++){
            if(bids[i].userid === IDuser){
                info.push({title: bids[i].post_title, value:bids[i].bid});
            }
        }
        //----------------- Favorites ------------
        let fav= [];
        if(favorites!== undefined){
            let favoritesvalues = favorites.map(fav => (fav));
            let product = products.map(p => (p));
            for (let a=0; a<favoritesvalues.length; a++){
                for (let i = 0; i<product.length; i++){
                    if (parseInt(favoritesvalues[a]) === parseInt(product[i].id)){
                        fav.push(product[i].name);
                    }
                }
            }
        }
        fav = fav.filter((item, i, ar) => ar.indexOf(item) === i);

		return (
			<Container className="mrg">
                <h1 className="accountTitle">Hallo <span>{username}</span></h1>
                <div className="center">
                <Link to="/"><button className="loginButton" onClick={this.logout}>Log out</button></Link>
                </div>
                <hr/>
                <div className="accountForm">
                <h2 className="accountSub">Auctions</h2>
                    <div className="accountBlock">
                <Row>
                    <Col >
                    
                    <h4 className="accountSub">Biedingen</h4>
                    <table className="accountAuctions">
                        <thead>
                        <tr>
                            <th>Product</th>
                            <th>Bid</th>
                        </tr>
                        </thead>
                            {info.slice(0, 10).map((bid,index)=> 
                            <tbody key={index}>
                                <tr>
                                    <td>
                                    {bid.title}
                                    </td>
                                    <td>
                                    {this.FormatBids(Math.round(`${bid.value}`))}
                                    </td>
                                </tr>
                            </tbody>
                            )}
                    </table>
                    
                    </Col>
                    </Row>
                    <Row className="mt-5 mb-5">
                    <Col>
                    <h4 className="accountSub">Favoriten</h4>
                    <table className="accountAuctions">
                    <thead>
                        <tr>
                            <th>Product</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fav.map(i =>
                         <tr>
                            <td>{i}</td>
                        </tr>   )}
                        
                    </tbody>
                    </table>
                    </Col>
                    </Row>
                    </div>
				</div>
					
			</Container>
		)
    }
    logout(){
        localStorage.removeItem('user');
    }
}
	


