import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ToastContainer, toast } from 'react-toastify';
import Carousel from 'react-bootstrap/Carousel';
import { Redirect } from 'react-router-dom';
//import CountdownTimer from '../misc/countdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faStar as FStar} from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
//import axios from 'axios';
import placeholder from '../../imgs/placeholder.jpg';

var store = require ('store')

class oldDetailProduct extends Component {
constructor(props){
    super(props);
        this.state ={
            bid : '',
            error: null,
        }
}

  getQuantity(product){
      var pos = product.meta_data;
      var quantity ="";

      for (let i = 0; i < pos.length; i++){
          if(pos[i].key==="woo_ua_max_quantity"){
              quantity = pos[i].value;
          }
      }
      return quantity;
  };

  getRetail(product){
    var pos = product.meta_data;
    var retail ="";

    for (let i = 0; i < pos.length; i++){
        if(pos[i].key==="woo_ua_retail_value"){
            retail = pos[i].value;
        }
    }
    return retail;
};

getMinBid(product){
    var pos = product.meta_data;
    var minbid ="";

    for (let i = 0; i < pos.length; i++){
        if(pos[i].key==="woo_ua_opening_price"){
            minbid = pos[i].value;
        }
    }
    return minbid;
};

    render() {
        if(!localStorage.getItem('user')){
			return <Redirect to="/login"/>;
		}
        var data = (this.props.location.product && this.props.location.product.data) ? this.props.location.product.data : false;
        const storeData = store.get('displayedProduct');
        if (data){
            store.set('displayedProduct', data);
        } else if(storeData){
            data = storeData;
        } else {
            toast.error('Error');
        }
        
        return (
            <Container className="mrg">
                <ToastContainer/>
            <h2 className="prodName">{data.name}</h2>
            <Row>
                <Col lg={7} xs={12}>
                <div className="prodGallery">
                <Carousel>
                    {data.images.map((image)=>
                        <Carousel.Item key={image.id}>
                        <img className="d-block w-100 prodGalleryItem" src={image ? image.src : placeholder} alt="gallery"/>
                        </Carousel.Item>
                    )}
                </Carousel>
                </div>
                </Col>
                <Col lg={5} xs={12}>
                <div className="helpButton"><a href="mailto:info@stocklife.eu?subject=Help%20with%20product&body=Please%20Indicate%20The%20Name%20Of%20The%20Product"><FontAwesomeIcon icon={faQuestionCircle} title="Do you have a question about this product?"/> Help</a></div>
                <span><h5 className="prodCat">Category:</h5> <div dangerouslySetInnerHTML={{  __html:data.categories[0].name}}/></span><br/>
                <span><h5 className="prodTime">Time left: Expired </h5></span><br/>
                <div className="prodHigh">
                <span><h5 className="prodMax">Quantity: </h5> <span className="prodValue">{this.getQuantity(data)} stuks</span></span><br/>
                <span><h5 className="prodRetail">Retail: </h5> <span className="prodValue">{this.getRetail(data)}€ </span></span><br/><br/>
                <span><h5 className="prodRetail">Min. bid: </h5> <span className="prodValue">{this.getMinBid(data)}€ </span></span><br/><br/>
                </div>
                </Col>
            </Row>
            <hr/>
            <div className="mt-5">
                <h5 className="prodDesc">Beschrijving</h5>
        <div dangerouslySetInnerHTML={{  __html:data.description}}/>
            </div>
            </Container>
        )
    }
}
export default oldDetailProduct;