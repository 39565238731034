import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



export default class Privacy extends Component {
    render(){
        return(
            <>
            <Container className="mrg">
            <h1 className="termsTitle">ALGEMENE GEBRUIKSVOORWAARDEN VAN STOCKLIFE B.V.</h1>
                <Row>
                    <Col>
                    <p>
                    1. Wie is verantwoordelijk voor de verwerking van uw gegevens?
StockLife B.V. is een besloten vennootschap onder Nederlands recht, gevestigd aan de Duinweg 15-17, (5482 VR) Schijndel, Nederland, geregistreerd bij de Nederlandse Kamer van Koophandel onder nummer 73714755 (hierna te noemen SL')

Voor de doeleinden die worden beschreven in deze Privacy- en cookievoorwaarden wordt SL als gegevensbeheerder' beschouwd, die de middelen en doelen bepalen voor de verzameling, opslag, openbaarmaking, gebruik en andere soorten verwerking van persoonsgegevens.

Elke aanduiding 'wij,' 'ons' of 'onze' in deze privacy- en cookievoorwaarden ('voorwaarden') verwijst naar SL. De Data protection officer (DPO) voor SL is:

Debbie Eijkemans
Duinweg 15
5482 VR SCHIJNDEL
Nederland
debbie@stocklife.nl

2. Doel van deze privacyverklaring en cookievoorwaarden
Deze privacy- en cookievoorwaarden beschrijven hoe SL de persoonlijke informatie gebruikt, die is verzameld bij deelnames aan veilingen, uw bezoek aan websites van SL, online applicaties en mobiele platforms. SL gaat zorgvuldig om met uw privacy en de veiligheid van uw persoonlijke gegevens.

Alle persoonlijke gegevens, die via de website of andere applicaties en mobiele platforms ('Platform') worden verzameld, worden verwerkt in overeenstemming met de toepasselijke wetgeving inzake gegevensbescherming, meer in het bijzonder de EU-richtlijn 2016/679, die bekend staat als Algemene Verordening Gegevensbescherming, evenals enige andere toekomstige wetgeving en aanvullende nationale wetgeving.

Deze privacy- en cookievoorwaarden zijn geldig met ingang van 25 mei 2018. Alle toekomstige wijzigingen in onze richtlijnen voor gegevensbescherming zullen op deze website worden gepubliceerd. Controleer daarom regelmatig de privacyverklaring voor wijzigingen.

3. Doel van de verwerking van persoonsgegevens
Wij gaan zorgvuldig om met uw persoonlijke gegevens en nemen maatregelen om deze te beschermen. In overeenstemming met wettelijke vereisten, moet u er rekening mee houden dat SL uw (persoonlijke) gegevens voor de volgende doeleinden zal verwerken en gebruiken:

• voor het uitvoeren van overeenkomsten;
• vanwege een legitiem belang van SL;
• om te voldoen aan wettelijke verplichtingen.

Meer in het bijzonder zullen onder de verantwoordelijkheid van SL uw (persoonlijke) gegevens worden verwerkt en gebruikt:
• om u online veilingdiensten aan te bieden (waaronder onder meer het faciliteren van online veilingen op de veilingwebsite, het registreren en sluiten van veilingbiedingen, het informeren van deelnemers over een veiling en de voortgang daarvan, het verstrekken van informatie aan een verkoper en/of klant als onderdeel van een veiling en/of een derde partij die betrokken is bij het uitvoeren van veilingen, het uitgeven van facturen);
• om uw (persoonlijke) registratiegegevens op te slaan in een gegevensbestand om uw (toekomstige) deelname aan een veiling of veilingen te faciliteren;
• om audits en veiligheidscontroles uit te voeren in relatie met de veilingwebsite;
• om u te informeren over functionele wijzigingen aan de veilingwebsite en u belangrijke informatie te verstrekken over deelname aan een veiling of het gebruik van veilingdiensten;
• om uw feedback te vragen op een nieuwe webpagina om de website te optimaliseren;
• in het geval u onze cookies accepteert: om uw ervaring op een anonieme manier te meten om de website te optimaliseren;
• in geval van technische problemen om deze op te lossen om zo uw bod op een kavel te kunnen traceren en om te voorkomen dat uw bod verloren gaat als gevolg van een technisch probleem;
• om u te informeren over mogelijk interessante veilingen bij SL en andere veilinghuizen van SL toen u zich inschreef voor een nieuwsbrief en toestemming gaf om uw gegevens te verwerken om u te informeren via nieuwsbrieven;
• om accounts van klanten in het centrale gegevensbestand te beheren;
• om vragen van bestaande en potentiële klanten af te handelen;
• om verkochte goederen af te leveren (bijvoorbeeld door een door SL in te schakelen bezorgdienst) of om (eventueel) ophaaldagen te organiseren;
• als u onze cookies accepteert of als u een account heeft gemaakt, om u specifieke aanbiedingen te doen op basis van uw surf- en aankoopgedrag wanneer u ons daarvoor toestemming hebt gegeven;
• om vragen van bestaande en potentiële klanten af te handelen;
• voor de afhandeling van klachten;
• voor het maken van uw factuur;
• om u de mogelijkheid te bieden goederen op te halen of om de bezorgdienst in staat te stellen het goed dat u hebt gekocht aan u te verzenden;
• om onze administratie te doen en onze belastingverplichtingen na te komen;
• om te voldoen aan wettelijke verplichtingen en rechterlijke uitspraken en/of maatregelen te nemen die vereist zijn in verband met een gerechtelijke procedure.

In alle andere gevallen zal SL uw toestemming vragen.

Voordat SL uw persoonlijke gegevens gebruikt voor marketingdoeleinden, zoals voor commerciële aanbiedingen (digitale nieuwsbrief), zal zij u om uw toestemming vragen op de veilingwebsite. U ontvangt alleen nieuwsbrieven wanneer u zich daarop heeft geabonneerd. Uw abonnement wordt door ons geregistreerd en opgeslagen zodat wij uw toestemming kunnen bewijzen. Als u geen marketinginformatie van SL meer wilt ontvangen, kunt u zich afmelden voor de nieuwsbrief met behulp van de daarvoor aanwezige hyperlink.

Aanbieders van advertentienetwerken en mediabedrijven fungeren als tussenpersonen voor webshops en adverteerders. De marketingcookies die zij gebruiken, stellen hen in staat om advertenties van derden te tonen op de website van SL. Zij kunnen uw favoriete kavels bij SL combineren met informatie die zij verzamelen wanneer u andere websites bezoekt. Deze informatie wordt niet verzameld, gecombineerd of gebruikt door SL, maar door de mediabedrijven en/of aanbieders van advertentienetwerken zelf, aan wie SL ruimte op haar website biedt (dit is vergelijkbaar met advertentieruimte in bijvoorbeeld kranten). SL is niet verantwoordelijk of aansprakelijk voor de gegevensverwerking van deze partijen.

Voor de cookie- en privacyverklaring van deze advertentienetwerken en bedrijven verwijst SL u graag naar hun respectievelijke websites. Omdat cookie- en privacyverklaringen van tijd tot tijd kunnen worden bijgewerkt, raden wij u aan regelmatig de cookie- en privacyverklaringen te bezoeken en te lezen.

4. Welke persoonsgegevens worden door ons verzameld?
Als onderdeel van het aanmaken van een account verzamelt SL alleen persoonsgegevens die nodig zijn om u diensten te verlenen en om de overeenkomst met u uit te voeren.

Voor het aanmaken van een account:
• Naam en adres van de maker van het account;
• Bedrijfsgegevens;
Contactpersonen/vertegenwoordigende organisaties (indien van toepassing);
• E-mailadressen en telefoonnummers.

Voor biedingen en voor het beheer van de website en de inhoud:
• Technische en gepseudonimiseerde gegevens;
• Uw feedback over het gebruik van ons Platform en alle andere informatie die u ons stuurt via de functionaliteiten van het Platform, die ons in staat stelt de functionaliteiten van onze website en Platform te verbeteren
• Anonieme gegevens die niet kunnen worden herleid naar een persoon, voor rapportagedoeleinden

Voor geslaagde biedingen / aankopen:
• Aangekochte kavel;
• Bankgegevens;
• Betalingsmethodes en wanneer de betaling is gedaan;
• Voor sommige kavels uw geboortedatum, indien wettelijk vereist;
• Leveradres.

5. Juridische basis voor gegevensverwerking
De juridische basis voor onze verwerkingsactiviteiten is tweeledig:
a. de uitvoering van de overeenkomst die u met ons heeft gesloten door uw registratie voor een account, om deel te nemen aan onze acties en de uitvoering van elke koopovereenkomst die we met u hebben gesloten na uw geslaagde bod;
b. ons legitieme belang als professionele veilingmeester om te communiceren met onze klanten en de deelnemers van onze veilingen en om ons platform te beheren zodat u kunt deelnemen aan onze veilingen;

Alleen voor het verzenden van marketing e-mails en nieuwsbrieven is de juridische basis anders. Wij sturen u alleen nieuwsbrieven als u hiervoor eerder toestemming heeft gegeven door u via onze website te abonneren op onze nieuwsbrieven. U kunt zich op elk gewenst moment uitschrijven voor deze mailings, zoals vermeld in paragraaf 3 van deze Privacyverklaring en Cookievoorwaarden.

6. Overige informatie
SL zal geen speciale categorieën persoonsgegevens verzamelen of opslaan, zoals gegevens over politieke meningen, religieuze overtuigingen, of gezondheid zoals gedefinieerd in artikel 9 AVG.

Gevoelige gegevens, zoals kredietwaardigheid, zijn alleen vereist in geval van bijzondere kavels op veilingen, zoals vliegtuigen of andere dure goederen, om te garanderen dat bieders dergelijke kavels daadwerkelijk kunnen kopen, en problemen met de betaling en levering kunnen worden voorkomen. In sommige gevallen werken we ook samen met notarissen en advocaten om te kunnen voldoen aan de wettelijke vereisten voor de eigendomsoverdracht.

7. Bescherming van persoonsgegevens
SL zorgt voor de effectieve bescherming van gegevens door gebruik te maken van versleutelde communicatie en veilige koppelingen in overeenstemming met de stand van de techniek waar nodig. SL heeft ook maatregelen genomen om de beveiliging van zowel fysieke als virtuele toegang tot gegevens in haar gebouwen en haar computersystemen te waarborgen.

Bovendien zullen wij de nodige administratieve, technische en organisatorische maatregelen implementeren om een niveau van beveiliging te waarborgen dat passend is voor de specifieke risico's die wij hebben geïdentificeerd.

8. Het bewaren van opgeslagen gegevens
Uw persoonlijke gegevens worden alleen voor de vereiste duur verwerkt en worden vervolgens verwijderd zodra ze niet langer nodig zijn, rekening houdend met wettelijke bewaarplicht voor gegevens. SL heeft een procedure voor het veilig bewaren van de documentatie in kwestie.

9. Uitwisseling gegevens
SL werkt samen met andere partijen om u diensten te verlenen en zal alleen informatie doorgeven aan derden die binnen de volgende scope vallen:
• Gelieerde bedrijven: gelieerde bedrijven die behoren tot SL en haar dochterondernemingen, indien deze bedrijven vergelijkbare gegevensbescherming bieden en de overdracht van gegevens is toegestaan.

• Dienstverleners: wij geven andere bedrijven en personen de opdracht om namens ons te werken. Voorbeelden van dienstverleners zijn bedrijven die bezorgdiensten of betalingsverwerking aanbieden (creditcard, automatische incasso en aankoop op rekening) of professionele biedagenten die namens klanten handelen. Als SL gebruik maakt van de diensten van derden, sluit het overeenkomsten met deze partijen, waaruit voor hen geen onafhankelijke verplichting ontstaat met betrekking tot de verwerking van persoonsgegevens. Deze dienstverleners hebben toegang tot de persoonlijke informatie die nodig is om hun werk uit te voeren. Het is hen echter niet toegestaan deze gegevens voor andere doeleinden te gebruiken.
• In alle andere gevallen wordt u op de hoogte gesteld als uw persoonlijke gegevens naar derden moeten worden verzonden en krijgt u de mogelijkheid om afzonderlijk hiermee in te stemmen.

Voor elke openbaarmaking zullen wij er zorg voor dragen dat de noodzakelijke contractuele waarborgen voor de bescherming van uw persoonlijke gegevens zijn ingesteld.

In alle gevallen waarin persoonlijke gegevens binnen de bovengenoemde context worden doorgegeven aan derden in landen buiten de Europese Unie (EU), garanderen wij dat de gegevens worden overgedragen in overeenstemming met deze Privacyverklaring en met toepasselijke gegevensbeschermingswetgeving.

In principe zullen alle gegevens waar mogelijk binnen de EU worden verwerkt en zullen deze niet worden uitgewisseld met partijen buiten de EU.

Gegevens mogen alleen door SL worden uitgewisseld met een niet-EU-land als de ontvanger hetzelfde niveau van gegevensbescherming kan waarborgen, hetzij op basis van de geldende wetgeving in dat land, hetzij via afzonderlijke overeenkomsten zoals voorgeschreven door de EU in het EU-format voor een bindende overeenkomst. Dit betekent ook dat we voor ons callcenter, dat zich buiten de EU bevindt, hebben gezorgd voor een adequate bescherming van uw persoonlijke gegevens door de ondertekening van EU-standaardcontractbepalingen.

10. Uw rechten met betrekking tot uw gegevens
U heeft de volgende wettelijke rechten:

Recht op informatie en recht op toegang tot uw persoonlijke gegevens. U kunt op elk gewenst moment meer informatie vragen over onze verwerkingsactiviteiten en de persoonlijke gegevens die wij van u bewaren.

Recht op rectificatie van onjuiste of onvolledige persoonlijke gegevens. U heeft het recht op onmiddellijke rectificatie of aanvulling door ons van alle onjuiste of onvolledige persoonlijke gegevens.

Recht op verwijdering van uw persoonlijke gegevens (het recht om te worden vergeten). U kunt verzoeken om (een deel van) uw persoonlijke gegevens te verwijderen in de volgende situaties:
a. wanneer de verwerking niet langer noodzakelijk is voor archiveringsdoeleinden waarvoor ze zijn verzameld of anderszins zijn verwerkt;

b. wanneer de verwerking niet langer nodig is om onze overeenkomst met u uit te voeren of wanneer dit niet langer wettelijk verplicht is;
c. wanneer de verwerking op toestemming was gebaseerd en u heeft besloten uw toestemming in te trekken;
d. wanneer uw gegevens onwettig zijn verwerkt;
e. wanneer u goede gronden heeft om bezwaar te maken tegen de verwerking van uw persoonlijke gegevens;
f. wanneer uw persoonlijke gegevens moeten worden gewist in overeenstemming met een wettelijke verplichting.

In uitzonderlijke gevallen kunnen we weigeren uw gegevens te verwijderen, om te voldoen aan wettelijke verplichtingen of om claims op te stellen, uit te oefenen of te verdedigen of voor enig ander legitiem belang.

Recht om de verwerking van persoonsgegevens te beperken. U kunt ons verzoeken om de verwerking van uw persoonlijke gegevens te beperken wanneer:
a. u de nauwkeurigheid daarvan heeft betwist, voor de periode die wij nodig hebben om uw persoonlijke gegevens te verifiëren;
b. wij de persoonsgegevens niet langer nodig hebben voor verwerking, maar u ze nodig heeft voor het instellen, uitoefenen of verdedigen van juridische claims;
c. de verwerking onwettig lijkt te zijn en u ons beperking van het gebruik van uw gegevens heeft gevraagd;
d. wij bezig zijn met het verifiëren of onze legitieme belangen de uwe overstijgen in het kader van een bezwaar tegen de verwerking van uw gegevens.

Recht op gegevensoverdracht. In sommige gevallen heeft u het recht om al uw persoonlijke gegevens te ontvangen in een gestructureerd, algemeen gebruikt en machinaal leesbaar formaat en heeft u het recht deze gegevens naar een andere controller te verzenden. Dit recht is van toepassing in geval de verwerking gebaseerd is op toestemming of op de noodzaak van de uitvoering van een contract, en wanneer de verwerking geautomatiseerd plaatsvindt.

Recht om bezwaar te maken tegen het verwerken van uw persoonlijke gegevens zonder kosten. U kunt bezwaar maken tegen de verwerking van uw persoonlijke gegevens, wanneer deze verwerking is gebaseerd op onze "legitieme belangen." In dat geval zullen wij uw gegevens niet langer verwerken, tenzij we dwingende legitieme redenen hebben om dit te doen, of omdat we dit vanwege een wettelijke verplichting moeten doen. Wanneer (een deel van) uw persoonsgegevens worden verwerkt voor direct marketingdoeleinden, kunt u op elk moment bezwaar maken tegen de verwerking van uw gegevens, of uw toestemming hiervoor intrekken. U heeft ook het recht om niet te worden onderworpen aan profilering voor direct marketingdoeleinden.

Klachtrecht. Als u een klacht heeft over onze gegevensverwerking, kunt u contact met ons opnemen door onze DPO te schrijven, zie voor contactgegevens paragraaf 1. U heeft ook het recht om uw klacht naar de lokale gegevensbeschermingsautoriteit te sturen. Waar SL de verantwoordelijke controller is, is de verantwoordelijke autoriteit voor gegevensbescherming de Nederlandse Autoriteit Persoonsgegevens.

Bij twijfel of er sprake is van de juiste persoon, mag SL om een extra identificatie vragen.

11. Cookies
SL gebruikt cookies op haar veilingwebsite om de functionaliteit te verbeteren en uw bezoek aan de website aangenamer te maken. Met behulp van de cookies kunnen wij u bijvoorbeeld herkennen bij uw volgende bezoek aan de veilingwebsite. De cookies stellen ons ook in staat om bepaalde statistieken te verzamelen voor statistische doeleinden, om het aantal paginabezoeken en de populariteit van individuele pagina's en onderwerpen te analyseren. Cookies zijn kleine tekstbestanden die door uw browser worden opgeslagen op uw computer, tablet of smartphone. Wij kunnen deze cookies gebruiken om informatie te verzamelen over het gebruik van de diensten en deze diensten waar mogelijk aan te passen aan uw behoeften.

Het is belangrijk om op te merken dat e-mail- en marketingcampagnes niet het gevolg zijn van cookies. Noch uw e-mailadres, noch uw telefoonnummer worden opgeslagen in cookies, en cookies worden niet gebruikt om traceerbare persoonlijke profielen te maken.

U heeft de mogelijkheid om alle cookies van bezochte websites te accepteren of uit te schakelen door uw internetbrowserinstellingen te wijzigen. Houd er rekening mee dat bepaalde functies op deze website niet beschikbaar zijn als u cookies uitschakelt. Als u niet zeker weet hoe u uw browserinstellingen moet wijzigen of cookies kunt verwijderen, raadpleegt u de instructies van uw browser.

Op onze website gebruiken wij de volgende cookies:

Sessie-cookies

Sessie-cookies worden gebruikt voor het beheren en beveiligen van uw huidige bezoek aan onze websites. Deze cookies worden automatisch verwijderd wanneer u uw browser afsluit. Een volledig overzicht van de cookies die momenteel worden gebruikt, hun functie en vervaltijdstip vindt u onderaan deze pagina.

Permanente cookies:

Door permanente cookies kunnen websites uw informatie en instellingen onthouden wanneer u ze in de toekomst opnieuw bezoekt. Dit resulteert in snellere en gemakkelijkere toegang, omdat u bijvoorbeeld niet opnieuw hoeft in te loggen. Het helpt u ook om kavels te selecteren en deze bij uw favorieten op te slaan, zodat u later terug kunt komen om ze te bekijken en u in een later stadium kunt beslissen om er in de veiling op te bieden. Het maakt het ook mogelijk om u te informeren wanneer een andere klant een hoger bod heeft geplaatst dan u, en om een nieuw bod te plaatsen om meer kans op succes te hebben op het winnen van het bod. Een volledig overzicht van de cookies die momenteel in gebruik zijn, hun functie en hun vervaltijdstip vindt u onderaan deze pagina.

12. Webbrowserinstellingen

Als u niet wilt dat websites cookies op uw computer opslaan, kunt u de instellingen van uw webbrowser wijzigen zodat er een waarschuwing verschijnt voordat cookies worden opgeslagen. U kunt de instellingen ook zo wijzigen dat de webbrowser alle cookies blokkeert of alleen die van externe providers. In uw webbrowserinstellingen kunt u ook cookies verwijderen die al zijn opgeslagen. Houd er rekening mee dat deze instellingen afzonderlijk moeten worden gedefinieerd in elke webbrowser en computer die u gebruikt. Houd er ook rekening mee dat SL niet kan garanderen dat haar website naar behoren zal functioneren als cookies worden geblokkeerd. Het kan zijn dat sommige websitefuncties verloren gaan of dat u geen toegang meer heeft tot de website. De procedure voor het wijzigen van de instellingen zijn voor elke webbrowser anders. Open zo nodig de helpfunctie van uw webbrowser om direct naar de instructies voor uw specifieke webbrowser te gaan.

U kunt cookies van bepaalde providers blokkeren op de volgende website: http://www.youronlinechoices.eu/.

13. Hyperlinks
De website van SL kan hyperlinks bevatten naar andere websites en naar websites van zakenpartners die geen deel uitmaken van SL. SL aanvaardt geen enkele verantwoordelijkheid voor de gegevensbeschermingspraktijken en de inhoud van deze websites.

14. Overige informatie
SL behoudt zich het recht voor om van tijd tot tijd wijzigingen aan te brengen in deze privacyverklaring en cookievoorwaarden. Controleert u dit elke keer dat u de website bezoekt om op de hoogte te blijven.

Als u nog vragen heeft over deze Privacyverklaring en Cookievoorwaarden, de website van SL of uw registratiegegevens, of als u deze gegevens wilt wijzigen, neemt u dan contact op met:
StockLife B.V.
Duinweg 15
5482VR SCHIJNDEL
info@stocklife.nl
en/of de DPO, zie voor de contactgegevens paragraaf 1.

15. Aanpassen privacy statement
Wij behouden ons het recht voor om deze privacy statement aan te passen. Wijzigingen zullen op deze website worden gepubliceerd.



                    </p>
                    </Col>
                </Row>
        </Container>
      </>
    );
  }  
}