import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons'


function Promotiepakket() {
  return (
    <>
      <Container>
        <h1 className="kopenHeading">Promotiepakket</h1>
      </Container>
      <Container fluid  className="underCarousel">
      <Container>
        <Row>
          <Col lg={4} sm={12}><div className="underA"><FontAwesomeIcon icon={faCheck} /> Anoniem</div></Col>
          <Col lg={4} sm={12}><div className="underB"><FontAwesomeIcon icon={faCheck} /> Goed Geregeld</div></Col>
          <Col lg={4} sm={12}><div className="underA"><FontAwesomeIcon icon={faCheck} /> Eeenvoudig</div></Col>
        </Row>
      </Container>
      </Container>
      <Container>
          <Row>
              <Col>
              <div className="promoContent">
              <p>Met het promotiepakket helpen wij u op te vallen tussen andere producten. 
              Dit doen wij door zelf bij u langs te komen en de fotografie te verzorgen. 
              Daarnaast zorgen wij voor 1 op 1 matching.</p>
              <p>Tijdelijk is het promotiepakket, t.w.v. €400,- geheel GRATIS!*</p>
              <p>Het promotiepakket bevat:</p>
              <ul>
                  <li>Fotografie</li>
                  <li>Opvallende advertenties</li>
                  <li>1 op 1 matching</li>
              </ul>
              <p>Zo hoeft u er niks voor te doen én ontvangt u gegarandeerd de beste biedingen!</p>
              <button className="stockButton2">Bied nu uw product aan met het promotiepakket</button>
              </div>
              </Col>
            </Row>
      </Container>
      <Container fluid className="full">
                <div className="promoHL">
                    <p>Een promotiepakket staat voor totale ontzorging</p>
                </div> 
      </Container>
    </>
  );
}

export default Promotiepakket;