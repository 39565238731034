import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import { Redirect, Link} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
//import axios from 'axios';



export default class Login  extends Component {

    constructor(props){
		super(props);
        this.state = {
		email: '',
		password : '',
		userData : [],
		agree : '',
    	}
		
	}
 
	render() {
		if(localStorage.getItem('user')){
			return <Redirect to="/myaccount"/>;
		}
		return (
			<Container className="mrg">
				<ToastContainer/>
				<h1 className="loginTitle">Welkom bij Stocklife</h1>
				<div className="loginForm">
					<form onSubmit={this.handleSubmit.bind(this)} method="POST">
							<label>Emailadres: <br/>
							<input className="loginInput" type="email" value={this.state.email} onChange={this.handleEmail}  />
							</label><br/>
							<label>Wachtwoord: <br/>
							<input className="loginInput" type="password" value={this.state.password} onChange={this.handlePassword}  />
							</label><br/>
						<button className="loginButton" type="submit">
							Login
						</button><br/><br/>
						<label className="loginAgree">
						<input type="checkbox" value={this.state.agree} onChange={this.handleAgree} required/><Link to="/privacy" target="_blank"> Ik accepteer de gebruiksvoorwaarden van Stocklife *</Link>
						</label>
					</form>
					<a href="https://backoffice.stocklife.eu/registreren/">
						<button className="registerButton">Ben je nog geen BlueMember? Klik hier (gratis registratie)</button>
					</a>
				</div>		
			</Container>
		)
	}

	handleEmail = (e) =>{
		this.setState({
			email : e.target.value
		});
	}

	handlePassword = (e) =>{
		this.setState({
			password : e.target.value
		});
	}
	handleAgree = (e) =>{
		this.setState({
			agree : e.target.value
		});
	}

	handleSubmit(e){
		e.preventDefault();
		const { history } = this.props;
			let formData = new FormData();
			formData.append('type', 'login');
			formData.append('email', this.state.email);
			formData.append('password', this.state.password);
	
			return fetch('https://backoffice.stocklife.eu/wp-json/srra/v1/getit', {
				method : 'POST',
				body :formData
			})
			.then(function(response){
				response.json().then(function(data) {
					let user = {
						authToken: data.data.auth_token,
						id: data.data.user_id,
						name: data.data.user_login,
						nicename : data.data.user_nicename,
						email : data.data.user_email
					}
					if(data.data.length !== 0){
						localStorage.setItem('user', JSON.stringify(user));		
						history.push('/');
					}else{
						toast.error("Invalid login");
					}
				})
			})
			.catch(function(error) {
				console.log('Fetch Error:', error);
			});
			
					
	}
	
}

