import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper, faStar } from '@fortawesome/free-solid-svg-icons';
import News1 from '../../imgs/news1.jpg';
import News2 from '../../imgs/news2.jpg';
import News3 from '../../imgs/news3.jpg';
import News4 from '../../imgs/news4.png';

function Nieuws() {
    return (
      <>
      
        <Container fluid className="nieuwsContent">
            <Container>
            <h1 className="nieuwsTitle">StockUpdates</h1>
                <Row>
                    <Col lg={12} sm={12}>
                    <div  className="nieuwsCol">
                        <img src={News1} alt="news1" width="100%"/>
                        <FontAwesomeIcon icon={faStar} className="nieuwsStar"/>
                        <FontAwesomeIcon icon={faStar} className="nieuwsStar"/>
                        <FontAwesomeIcon icon={faStar} className="nieuwsStar"/>
                        <FontAwesomeIcon icon={faStar} className="nieuwsStar"/>
                        <FontAwesomeIcon icon={faStar} className="nieuwsStar"/>
                        <div className="nieuwsNews">
                            <h2>Welkom bij de Stockupdates!</h2>
                            <FontAwesomeIcon icon={faNewspaper} className="nieuwsIcon"/> 
                            <h3>“From scratch tot een plan”</h3>
                            <h5>The Blue Team</h5>
                        </div>
                    </div>
                    </Col>
                </Row>
            </Container>
        </Container>
        <Container fluid className="nieuwsContent">
            <Container>
                <Row>
                    <Col lg={12} sm={12}>
                    <div  className="nieuwsCol">
                        <img src={News2} alt="news2" width="100%"/>
                        <FontAwesomeIcon icon={faStar} className="nieuwsStar"/>
                        <FontAwesomeIcon icon={faStar} className="nieuwsStar"/>
                        <FontAwesomeIcon icon={faStar} className="nieuwsStar"/>
                        <FontAwesomeIcon icon={faStar} className="nieuwsStar"/>
                        <FontAwesomeIcon icon={faStar} className="nieuwsStar"/>
                        <div className="nieuwsNews">
                            <FontAwesomeIcon icon={faNewspaper} className="nieuwsIcon"/> 
                            <h3>“Een nieuwe markt om restvoorraden te verkopen voor bedrijven”</h3>
                            <h5>The Blue Team</h5>
                        </div>
                    </div>
                    </Col>
                </Row>
            </Container>
        </Container>
        <Container fluid className="nieuwsContent">
            <Container>
                <Row>
                    <Col lg={12} sm={12}>
                    <div  className="nieuwsCol">
                        <img src={News3} alt="news3" width="100%"/>
                        <FontAwesomeIcon icon={faStar} className="nieuwsStar"/>
                        <FontAwesomeIcon icon={faStar} className="nieuwsStar"/>
                        <FontAwesomeIcon icon={faStar} className="nieuwsStar"/>
                        <FontAwesomeIcon icon={faStar} className="nieuwsStar"/>
                        <FontAwesomeIcon icon={faStar} className="nieuwsStar"/>
                        <div className="nieuwsNews">
                            <FontAwesomeIcon icon={faNewspaper} className="nieuwsIcon"/> 
                            <h3>“Ook in de Food branche actief en enthousiaste response!”</h3>
                            <h5>The Blue Team</h5>
                        </div>
                    </div>
                    </Col>
                </Row>
            </Container>
        </Container>
        <Container fluid className="nieuwsContent">
            <Container>
                <Row>
                    <Col lg={12} sm={12}>
                    <div  className="nieuwsCol">
                        <img src={News4} alt="news4" width="100%"/>
                        <FontAwesomeIcon icon={faStar} className="nieuwsStar"/>
                        <FontAwesomeIcon icon={faStar} className="nieuwsStar"/>
                        <FontAwesomeIcon icon={faStar} className="nieuwsStar"/>
                        <FontAwesomeIcon icon={faStar} className="nieuwsStar"/>
                        <FontAwesomeIcon icon={faStar} className="nieuwsStar"/>
                        <div className="nieuwsNews">
                            <FontAwesomeIcon icon={faNewspaper} className="nieuwsIcon"/> 
                            <h3>“Live sinds begin 2019 en veel enthousiaste aanmeldingen!”</h3>
                            <h5>The Blue Team</h5>
                        </div>
                    </div>
                    </Col>
                </Row>
            </Container>
        </Container>
      </>
    );
  }
  
  export default Nieuws;