import React from 'react';
//import SliderMain from '../misc/slider';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import imgBuy from'../../imgs/buy.svg';
import imgSell from'../../imgs/sell.svg';
import google from '../../imgs/google-play-badge.png';
import apple from '../../imgs/apple-badge.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faUserTie, faUserSecret, faCoins, faTruckMoving, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";
import CarouselMain from "../misc/carousel/carousel.js";
import Moneycard from "../../imgs/moneycard.svg";


function Homepage() {
  return (
    <>
      <Container fluid className="p-0">
      <Container fluid className="p-0">
          <div className="APP">
            <h3>Download the APP</h3>
            <div className="APP-dwnld">
              <span className="google">
                <a href="https://play.google.com/store/apps/details?id=com.brunoapereira89.StocklifeApp"><img src={google} alt="google-store"/></a>
              </span>
              <span className="apple">
                <a href="https://apps.apple.com/us/app/stocklife/id1533323307"><img src={apple} alt="google-store"/></a>
              </span>
            </div>
          </div>
      </Container>
        <div className="imageNotSlider">
          <h1>STOCKLIFE IS HET VEILINGPLATFORM VOOR ONDERNEMERS</h1>
          <h2>Jouw voorraad snel verkopen en omzetten in geld<br/>Bieden op interessante nieuwe partijen</h2>
          <span className="buttonSlider">
            <Link to="/verkopen"><button className="stockButton3 leftOne">Voorraad anoniem en snel verkopen</button></Link>
            <Link to="/kopen"><button className="stockButton3">Snel en anoniem bieden en kopen</button></Link>
          </span>
        </div>
      </Container>
      <Container fluid  className="underCarousel">
      <Container>
        <Row>
          <Col lg={4} sm={12}><div className="underA"><FontAwesomeIcon icon={faCheck} /> Anoniem</div></Col>
          <Col lg={4} sm={12}><div className="underB"><FontAwesomeIcon icon={faCheck} /> Goed Geregeld</div></Col>
          <Col lg={4} sm={12}><div className="underA"><FontAwesomeIcon icon={faCheck} /> Eenvoudig</div></Col>
        </Row>
      </Container>
      </Container>
      <Container className="home-info">
        <h2>Stocklife is een Online veilingplatform voor ondernemers waarmee je anoniem en snel restvoorraden kunt verkopen en kunt kopen. 
          Stocklife ontzorgt in het gehele proces en hanteert no cure no pay. Stoclife werkt, gegarandeerd!
        </h2>
      </Container>
      <Container className="mainNotice">
        <Row>
          <Col lg={4} md={4}>
            <FontAwesomeIcon className="mainNoticeIcon" icon={faUserTie}/>
            <h4>Alleen voor bedrijven</h4>
          </Col>
          <Col lg={4} md={4}>
            <FontAwesomeIcon className="mainNoticeIcon" icon={faUserSecret}/>
            <h4>Anoniem</h4>
          </Col>
          <Col lg={4} md={4}>
            <img src={Moneycard} alt="moneycard" className="mainNoticeIcon" />
            <h4>Niets verkocht is niets betalen</h4>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={4}>
            <FontAwesomeIcon className="mainNoticeIcon" icon={faCoins}/>
            <h4>Hoger rendement</h4>
          </Col>
          <Col lg={4} md={4}>
            <FontAwesomeIcon className="mainNoticeIcon" icon={faTruckMoving}/>
            <h4>Alles is geregeld (ook transport)</h4>
          </Col>
          <Col lg={4} md={4}>
            <FontAwesomeIcon className="mainNoticeIcon" icon={faStopwatch}/>
            <h4>Gaat je veel tijd besparen</h4>
          </Col>
        </Row>
      </Container>
      <Container fluid className="homeButtons">
        <Container>
        <Row className="carouselRow">
        <CarouselMain/>
        </Row>
        <Row>
          <Col lg={6} sm={6} xs={12} className="homeFix"><Link to="/shop"><button className="stockButton">Meer producten</button></Link></Col>
          <Col lg={6} sm={6} xs={12} className="homeFix"><Link to="/gesloten"><button className="stockButton">Gesloten Veilingen</button></Link></Col>
        </Row>
        </Container>
      </Container>
      <Container fluid className="howItWorks">
        <div>
          <h1 className="mainHow">HOE WERKT HET VEILINGPLATFORM STOCKLIFE</h1>
          <h2 className="mainHowDesc">
            Je restvoorraad snel en anoniem verkopen of bieden op interessante voorraden?<br/>
            Dit kan nu zeer gemakkelijk met Stocklife. Het online veilingplatform voor ondernemers.
          </h2>
        </div>
        <Container>
        <Row>
          <Col lg={6} sm={6} xs={12}><div className="mainIcon"><div><img src={imgSell} alt="sell"/></div>
          <Link to="/verkopen"><button className="stockButton2">AANBIEDEN</button></Link></div>
          </Col>
          <Col lg={6} sm={6} xs={12}>
          <div className="mainIcon">
            <div>
              <img src={imgBuy} alt="buy"/>
            </div>
          <Link to="/kopen"><button className="stockButton2">BIEDEN</button></Link></div>
          </Col>
        </Row>
        </Container>
      </Container>
    </>
  );
}

export default Homepage;