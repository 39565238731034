import React from 'react';
//import axios from 'axios';
import {Progress} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class NewFormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      phone: '',
      email: '',
      product: '',
      description: '',
      reason: '',
      selectedOption:'',
      promotiepakket: '',
      selectedFile: null, loaded:0}
  }

  handleSubmit(event) {
    event.preventDefault();
    let formData = new FormData();
        formData.append('type', 'form');
        formData.append('name', this.state.name );
        formData.append('phone', this.state.phone);
        formData.append('email',this.state.email);
        formData.append('product', this.state.product);
        formData.append('description', this.state.description);
        formData.append('reason', this.state.reason);
        formData.append('selectedOption', this.state.selectedOption);
        formData.append('promotiepakket', this.state.promotiepakket);
        if(this.state.selectedFile == null){
          toast.error("Please upload a file.")
        }else{
          const files = this.state.selectedFile;
          for (let i = 0; i < files.length; i++) {
              formData.append(`selectedFile[${i}]`, files[i])
          }
        }
        
       return fetch('https://backoffice.stocklife.eu/wp-json/srra/v1/upload',{
              method: 'POST',
              body: formData
          })
          .then(response => toast.success("Product Submited"))
          .catch(error =>{
            console.log(error);
          });
  }
  
  resetForm(){
    this.setState({name: '', phone: '', email: '', product: '', description: '', reason: '', selectedOption:false, promotiepakket:false, selectedFile: null, loaded:0})
 }

  

  render() {
    return (
      <>
      <div className="form-group">
        <ToastContainer />
        </div>
      <form onSubmit={this.handleSubmit.bind(this)} method="POST" className="aanbiedenForm">
        <label>
          UW NAAM *<br/>
          <input type="text" className="inputForm" value={this.state.name} onChange={this.handleName.bind(this)} required />
        </label>
        <label>
        UW TELEFOONNUMMER<br/>
          <input type="number" className="inputForm" value={this.state.phone} onChange={this.handlePhone.bind(this)} />
        </label>
        <label>
        UW MAILADRES *<br/>
          <input type="email" className="inputForm" value={this.state.email} onChange={this.handleEmail.bind(this)} required/>
        </label>
        <label>
          NAAM PRODUCT *<br/>
          <input type="text" className="inputForm" value={this.state.product} onChange={this.handleProduct.bind(this)} required />
        </label>
        <label>
          OMSCHRIJVING *<br/>
          <input type="text" className="inputForm" value={this.state.description} onChange={this.handleDescription.bind(this)} required />
        </label>
        <label>
          REDEN VAN VERKOOP *<br/>
          <input type="text" className="inputForm" value={this.state.reason} onChange={this.handleReason.bind(this)} required />
        </label>
        <div className="radio">
          <label className="fixForm">
            BEZICHTIGING *</label>
            <label className="radioValue">
            <input className="fixRadio" type="radio" value="ja" checked={this.state.selectedOption === 'ja'} onChange={this.handleView} required /> Ja</label>
            <label className="radioValue">
            <input className="fixRadio" type="radio" value="nee" checked={this.state.selectedOption === 'nee'} onChange={this.handleView} required /> Nee</label>
        </div>
          
        <label>
            UPLOAD FOTO'S (.jpg only) * <br/>
        <input type="file" accept=".jpg" name="file" multiple onChange={this.handleFile} /> 
        <Progress max="100" color="success" value={this.state.loaded} >{Math.round(this.state.loaded,2) }%</Progress> </label>
        
        <div className="radio">
          <label className="fixForm">
          PROMOTIEPAKKET? *</label>
          <label className="radioValue">
            <input className="fixRadio" type="radio" value="ja" checked={this.state.promotiepakket === 'ja'} onChange={this.handlePromo} required /> Ja</label>
            <label className="radioValue">
            <input className="fixRadio" type="radio" value="nee" checked={this.state.promotiepakket === 'nee'} onChange={this.handlePromo} required /> Nee</label>
            <p className="formDesc">Tijdelijk is het promotiepakket gratis! Dus 0 risico en extra voordelig.</p>
        </div>
        <button className="stockButton3" type="submit" onClick={this.onClickHandler}>STUUR</button>
      </form>
      </>
    );
  }

  handleName = (event) => {
    this.setState({name: event.target.value});
  }
  handlePhone = (event) => {
    this.setState({phone: event.target.value});
  }
  handleEmail = (event) => {
    this.setState({email: event.target.value});
  }
  handleProduct = (event) => {
    this.setState({product: event.target.value});
  }
  handleDescription = (event) => {
    this.setState({description: event.target.value});
  }
  handleReason = (event) => {
    this.setState({reason: event.target.value});
  }
  handleView = (event) => {
    this.setState({selectedOption: event.target.value});
  }
  handlePromo = (event) => {
    this.setState({promotiepakket: event.target.value});
  }

  handleFile = (event) =>{
    var files = event.target.files
         this.setState({
         selectedFile: files
      })
   }
   
}


export default NewFormContainer;