import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Super from '../../imgs/scheme.jpg';
import Scheme from '../../imgs/newscheme.jpg';
import imgBuy from '../../imgs/buy.svg';
import imgSell from '../../imgs/sell.svg';
import Johan from '../../imgs/johan.jpg';
import Leon from '../../imgs/leon.jpg';
import Debbie from '../../imgs/debbie.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import {Link} from "react-router-dom";




function OverOns() {
  return (
    <>
      <Container className="overHeading">
          <h2 className="overTitle">Geschiedenis Stocklife</h2>
          <Row>
              <Col lg={8}>
                <p className="overText">
                    <span className="green">Stocklife</span> is ontstaan door onze jarenlange ervaring in de logistiek. 
                    Wij lopen namelijk in de praktijk vaak tegen restvoorraden aan. 
                    Hier wordt tot nu toe niets of heel weinig mee gedaan. 
                    Het ligt tevens vaak in de weg, terwijl het voor andere bedrijven zeer waardevolle producten zijn. 
                    Alleen hoe ontmoeten deze twee bedrijven elkaar? 
                    En hoe kan dit volledig anoniem zonder teveel moeite geregeld worden? 
                    Hier was eerder nog geen oplossing voor. 
                    Tot nu! Het platform genaamd Stocklife waar vraag en aanbod van restvoorraden elkaar vinden. 
                    Stocklife geeft een tweede leven aan restvoorraden en een goede en eerlijke prijs voor zowel de koper als verkoper.</p>
                    <p className="overBlue">
                    Daarom hebben we een B2B handelsplatform voor bedrijven ontwikkeld. 
                    We hebben al vele kopende en verkopende bedrijven aangesloten, dus ben er snel bij en mis niets!
                    </p>
                    <p className="green">Een mail sturen naar info@stocklife.eu is voldoende. <span role="img" aria-label="emoji">🙂</span>  Wij doen niet zo moeilijk.</p>
              </Col>
              <Col lg={4}>
                  <img src={Super} alt="super" width="300px"/>
              </Col>
          </Row>
          <div className="overImg overRow">
              <img src={Scheme} alt="scheme" width="300px" />
            </div>
          <Row>
              <Col lg={6} sm={12} className="kopenFix">
              <h4 className="overWaarom">Waarom Stocklife?</h4>
              <p className="overText2">Wij geloven in een volgend avontuur voor ‘dode’ voorraad. 
                  Voor u als ondernemer drukt dit vaak ongemerkt (hevig) op uw rendement. 
                  <br/>Stocklife brings the old products to life, ook wel de ‘blue way’ genoemd (no waste).</p>
                  <p className="overText2">Verspilling wordt dus tegen gegaan omdat we deze ‘blauwe’ producten een nieuw ‘baasje’ geven.</p>
                  <p className="slogan"><span className="overBlue">Stocklife =</span><span className="green"> The Blue way</span> <span className="overBlue">= no waste</span></p>
              </Col>
              <Col lg={6} sm={12}>
              <h4 className="overWaarom">Hoe werkt het?</h4>
                <p>Wij hebben een B2B platform ontwikkeld: www.stocklife.eu. 
                  Hier ontmoeten de kopende en verkopende partijen elkaar.</p>
                <p>Bij Stocklife is alles anoniem. 
                De verkopende partij biedt zijn voorraad anoniem aan en de kopende partijen doen anoniem hun bod.</p>
                <p>Stocklife maakt de foto’s, zodat uniformiteit is gegarandeerd.
                    <br/>Dit verhoogt de betrouwbaarheid en dus de waarde.
                    <br/>Zo verkrijgen beide partijen de beste prijs.</p>
                <p>Stocklife verzorgt de gehele logistiek. We zijn zeer flexibel met ons eigen transportbedrijf. 
                    Op deze manier nemen wij het gehele proces voor u uit handen. 
                    Alle zekerheid voor u dus. </p>
                    <p>Wij screenen onze (partner)klanten vooraf. 
                        Dus is het voor iedereen vertrouwd & fijn zakendoen.</p>
              </Col>
          </Row>
      </Container>
      <Container fluid className="full">
                <div className="overHL">
                    <h3 className="overHelpTitle">Heeft uw bedrijf “last” van restvoorraden?</h3>
                    <p>Ligt het te verstoffen in uw magazijn?</p>
                    <p>Drukt het op uw rendement?</p>
                    <p>Wilt u het graag verkopen?</p>
                    <h4>Stocklife helpt! info@stocklife.eu</h4>
                </div> 
      </Container>
      <Container className="overKortom">
          <h3>Kortom:<b><i> Stocklife voor u als ondernemer</i></b></h3>
          <h5><u>Als aanbieder</u>:</h5>
          <p>Handig, Snel, Betrouwbaar, Minder voorraad, Beste rendement, Ruimte, Anoniem, No waste</p>
          <h5><u>Als Bieder</u>:</h5>
          <p>Handig, Snel,  Betrouwbaar, Unieke producten, Bezorgfunctie</p>
          <h1>Stocklife<span className="smallcp">&#174;</span> – The Blue way</h1>
      </Container>
      <Container fluid className="howItWorks">
        <div>
          <h2 className="mainHow">Hoe het werkt</h2>
          <p className="mainHowDesc">Uw restvoorraden verkopen of voorraad inkopen? Registreer u nu geheel gratis!</p>
        </div>
        <Container>
        <Row>
          <Col lg={6} sm={6} xs={12}><div className="mainIcon"><div><img src={imgSell} alt="sell"/></div>
          <Link to="/verkopen"><button className="stockButton2">AANBIEDEN</button></Link></div>
          </Col>
          <Col lg={6} sm={6} xs={12}><div className="mainIcon"><div><img src={imgBuy} alt="buy"/></div>
          <Link to="/kopen"><button className="stockButton2">BIEDEN</button></Link></div>
          </Col>
        </Row>
        </Container>
      </Container>
      <Container className="mrg">
          <Row>
          <Col lg={4} sm={7} xs={8}><h5 className="teamTitle">Team</h5></Col>
          </Row>
          <Row>
              <Col lg={4} sm={12} className="kopenFix">
              <div className="teamItem">
                  <img src={Johan} alt ="Johan" width="100%"/>
                  <h4>Johan Senders</h4>
                  <h6>Blue Manager</h6>
                  <span className="overSocial">
                    <a href="https://www.linkedin.com/in/johan-senders-688a103a/" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faLinkedinIn}/>
                    </a>
                  </span>
                  <p>Jarenlange ervaring in expeditie en transport</p>
              </div>
              </Col>
              <Col lg={4} sm={12} className="kopenFix">
              <div className="teamItem">
                  <img src={Leon} alt="Leon" width="100%"/>
                  <h4>Leon VD Elsen</h4>
                  <h6>Blue Manager</h6>
                  <span className="overSocial">
                    <a href="https://www.linkedin.com/in/leonelsen/" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faLinkedinIn}/>
                    </a>
                  </span>
                  <span className="overSocial">
                    <a href="https://wa.me/31615309726" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faWhatsapp}/>
                    </a>
                  </span>
                  <p>leon@stocklife.eu</p>
              </div>
              </Col>
              <Col lg={4} sm={12} className="kopenFix">
              <div className="teamItem">
                  <img src={Debbie} alt="Debbie" width="100%"/>
                  <h4>Debbie van den Langenberg - Eijkemans</h4>
                  <h6>Blue Manager</h6>
                  <span className="overSocial">
                    <a href="https://www.linkedin.com/in/debbie-eijkemans-948a34182/" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faLinkedinIn}/>
                    </a>
                  </span>
                  <span className="overSocial">
                    <a href="https://wa.me/31613320025" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faWhatsapp}/>
                    </a>
                  </span>
                  <p>debbie@stocklife.eu</p>
              </div>
              </Col>
          </Row>
      </Container>
    </>
  );
}


export default OverOns;