import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import Countdown from 'react-countdown';
import placeholder from '../../imgs/placeholder.jpg';
import google from '../../imgs/google-play-badge.png';
import apple from '../../imgs/apple-badge.svg';

export default class Binnenkort extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products:[],
      isLoading : false,
      error: null,
      data: null,
    };
  }

chamarfuncao(product){
  var pos1 = product.meta_data;
  var difference = "";

   for (let x = 0; x<pos1.length; x++){
      if(pos1[x].key === "woo_ua_auction_start_date"){
          difference = new Date(Date.parse(pos1[x].value));
      }
    }
  return difference;
}

  
  componentDidMount() {
    this.setState({ isLoading: true });
      fetch('https://backoffice.stocklife.eu/wp-json/wc/v3/products?per_page=20&consumer_key=ck_b1c80573279ce73665e33d52a9c2c7819309c871&consumer_secret=cs_43dfbd043b03acfb6ffbc5e2d51abe0e84cdd6f6',
        {
          method: 'GET',
        })        
        .then(response => response.json())
        .then(data => this.setState({ products: data, isLoading: false}));
  }

  
   
  render() {
    
    const { products, isLoading } = this.state;
    if (isLoading) {
      return <div className="fullScreen"><div className="sk-folding-cube">
      <div className="sk-cube1 sk-cube"></div>
      <div className="sk-cube2 sk-cube"></div>
      <div className="sk-cube4 sk-cube"></div>
      <div className="sk-cube3 sk-cube"></div>
    </div></div>;
    }  

    //-------------Filter-------------
    var pos1 = products.map((product) =>(product.meta_data));

    let closedIds = [];
    let open = [];
    let comingSoon = [];
    let dia = new Date();

    for(let i = 0; i<pos1.length; i++){
      var tamanho = pos1[i].length;

      for(let x= 0; x<tamanho; x++){
        if(pos1[i][x].key === "woo_ua_auction_start_date"){
         if (Date.parse(pos1[i][x].value) > Date.parse(dia))
          comingSoon.push(i);
        }
      }
    }

      for (let i =0;i<pos1.length;i++){

        var pos5 = pos1[i].length;

        for (let x = 0; x<pos5; x++){
          if(pos1[i][x].key === "woo_ua_auction_closed"){
            closedIds.push(i);
        }
      }
    }
      for(let i = 0; i<pos1.length; i++){
        if(!closedIds.includes(i) && products[i].status === "publish" && comingSoon.includes(i)){
          open.push(products[i]);
        }
      }
      
      //--------END OF FILTER------------
      const Completion = () => <span>Closed</span>;
      const renderer = ({days, hours, minutes, seconds, completed})=>{
        if(completed){
          return <Completion/>
        } else{
          return(
            <span>
              {days}d {hours}h {minutes}m {seconds}s
            </span>
          );
        }
      };
      
  return (
    <Container className="mrg">
       <div className="APP-shop">
            <h3>Download the APP</h3>
            <div className="APP-dwnld">
              <span className="google">
                <a href="https://play.google.com/store/apps/details?id=com.brunoapereira89.StocklifeApp"><img src={google} alt="google-store"/></a>
              </span>
              <span className="apple">
                <a href="https://apps.apple.com/us/app/stocklife/id1533323307"><img src={apple} alt="google-store"/></a>
              </span>
            </div>
        </div>
  <div>
    {open.map(product =>
       <div className="shopItem" key={product.id}>
          <div className="shopCard">
          <img src={product.images[0] ? product.images[0].src : placeholder} className="shopImg" alt="test"/>
          <div className="shopCat mt-3">
          <div dangerouslySetInnerHTML={{  __html:product.categories[0].name}}/>  
          </div>
          <div className="shopTimer"><Countdown date = {this.chamarfuncao(product)} renderer={renderer}/></div>
          <div className="shopTitle">{product.name}</div>
          </div>
        </div>  
        )}
  </div>
  </Container>
  );
}
}