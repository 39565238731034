import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import placeholder from '../../imgs/placeholder.jpg';
import {Link} from "react-router-dom";

export default class Gesloten extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products:[],
      isLoading : false,
      error: null,
      data: null,
    };
  }
  
  componentDidMount() {
    
    this.setState({ isLoading: true });
    fetch('https://backoffice.stocklife.eu/wp-json/wc/v3/products?per_page=20&consumer_key=ck_b1c80573279ce73665e33d52a9c2c7819309c871&consumer_secret=cs_43dfbd043b03acfb6ffbc5e2d51abe0e84cdd6f6',
      {
        method: 'GET',
      })        
      .then(response => response.json())
      .then(data => this.setState({ products: data, isLoading: false}));
  }


   
  render() {
    const { products, isLoading } = this.state;
    if (isLoading) {
      return <div className="fullScreen"><div className="sk-folding-cube">
      <div className="sk-cube1 sk-cube"></div>
      <div className="sk-cube2 sk-cube"></div>
      <div className="sk-cube4 sk-cube"></div>
      <div className="sk-cube3 sk-cube"></div>
    </div></div>;
    }

  let closedIds = [];  
  var pos1 = products.map((product) =>(product.meta_data));

      for (let i =0;i<pos1.length;i++){
        var pos5 = pos1[i].length;
        for (let x = 0; x<pos5; x++){
          if(pos1[i][x].key === "woo_ua_auction_closed"){
            closedIds.push(products[i]); 
      }
    }
    }


  return (
    <Container className="mrg">
  <div>
    {closedIds.map(product =>
       <div className="shopItem" key={product.id}>
          <div className="shopCard">
          <Link to={{pathname:`/olddetailproduct/${product.slug}`, product: {'data':product}}}><img src={product.images[0] ? product.images[0].src : placeholder} className="shopImg closed" alt="test"/></Link>
          <div className="shopCat mt-3">
            <div className="closed" dangerouslySetInnerHTML={{  __html:product.categories[0].name}}/>  
          </div>
          <div className="shopTitle closed">{product.name}</div>
          </div>
        </div>  
        )}
  </div>
  </Container>
  );
}
}