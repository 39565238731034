import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Leon from '../../imgs/leon.jpg';
import Debbie from '../../imgs/debbie.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebookSquare, faInstagram, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import MailchimpSubscribe from "react-mailchimp-subscribe";



function Contact() {
  return (
    <>
      <Container>
        <h1 className="kopenHeading">CONTACT</h1>
      </Container>
      <Container>
        <Row>
          <Col lg={4} sm={7} xs={8}>
              <h5 className="contactTitle">Wij helpen u graag <span role="img" aria-label="emoji">🙂</span></h5>
          </Col>
        </Row> 
      </Container>
      <Container>
          <Row>
              <Col lg={6} sm={12}>
                  <div className="contactBlock1">
                      <div className="contactPerson">
                          <div className="contactPersonImg">
                              <img src={Leon} alt="Leon" className="contactImg"/>
                          </div>
                          <div className="contactPersonText">
                              <p><b>Leon vd Elsen</b></p>
                              <p>Blue Manager</p>
                              <p>+31-615 309 726</p>
                              <p>leon@stocklife.eu</p>
                          </div>
                      </div>
                      <div className="contactPerson2">
                          <div className="contactPersonImg">
                              <img src={Debbie} alt="Debbie" className="contactImg"/>
                          </div>
                          <div className="contactPersonText">
                              <p><b>Debbie Eijkemans</b></p>
                              <p>Blue Manager</p>
                              <p>+31-613 320 025‬</p>
                              <p>debbie@stocklife.eu</p>
                          </div>
                      </div>
                  </div>
              </Col>
              <Col lg={6} sm={12}>
              <div className="contactBlock2">
                  <h6 className="contactMapTitle">KOM OP BEZOEK</h6>
              <div class="mapouter">
                  <div class="gmap_canvas">
                      <iframe title="map" width="100%" height="330" id="gmap_canvas" src="https://maps.google.com/maps?q=Duinweg%2015-17%205482VR%20Schijndel%20Nederland&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"/>
                  </div>
                </div>
              </div>
              </Col>
          </Row>
          <Row className="contact-margin">
          <Col lg={6} sm={12}>
          <div className="contactBlock3">
              <h3>MELD U AAN VOOR ONZE NIEUWSBRIEF!</h3>
              <MailchimpSubscribe url="https://stocklife.us20.list-manage.com/subscribe/post?u=12359c973990e0082a90e8cf1&amp;id=2bd42a8409" />
          </div>
          </Col>
          <Col lg={6} sm={12}>
              <div className="contactAdress">
                <h3>ADRES</h3>
                <p>Duinweg 15-17</p>
                <p>5482VR Schijndel</p>
                <p>Nederland</p><br/>
                <p>info@stocklife.eu</p>
                <h5>VOLG ONS OP SOCIAL MEDIA!</h5>
                <div>
                    <span className="contactSocial">
                        <a href="https://www.linkedin.com/company/stocklifebv/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedin}/>
                        </a>
                    </span>
                    <span className="contactSocial">
                        <a href="https://www.facebook.com/StocklifeAuction/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebookSquare}/>
                        </a>
                    </span>
                    <span className="contactSocial">
                        <a href="https://www.instagram.com/stocklife.eu/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram}/>
                        </a>
                    </span>
                    <span className="contactSocial">
                        <a href="https://www.youtube.com/channel/UCLL3LyMV-giOhTcZH5FVuIA" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faYoutubeSquare}/>
                        </a>
                    </span>
                </div>
              </div>
          </Col>
          </Row>
      </Container>
    </>
  );
}

export default Contact;